<template>
  <transition name="show_view">
    <div class="screen_cent" v-show="isshow">
      <div class="screen_data" transiton="show_view">
        <table class="dataintable">
          <tr>
            <td style="width: 120px; height: 80px; vertical-align: middle">
              商品名称<span>*</span>
            </td>
            <td style="vertical-align: middle">{{currentSInfo.pname}}
            </td>
          </tr>
          <tr>
            <td>税率</td>
            <td><select id="sltTax" :value="currentSInfo.taxid">

              <option :value="item.id" v-for="(item,index) in taxesList" :key="index">{{item.name}}</option>
            </select>
            </td>
          </tr>
          <tr>
            <td>押金</td>
            <td><input type="text" id="txtDeposit" onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)" onblur="this.value=this.value.match(/\d+\.?\d{0,2}/)" :value="currentSInfo.deposit" /></td>
          </tr>
          <tr>
            <td>小费</td>
            <td ><div style="display:flex;align-items: center;padding-top:5px;"><input  type="radio" name="deliveryType" value="1" v-model="currentSInfo.tipstype"/> 百分比  
          <input  type="radio" name="deliveryType" value="2"  v-model="currentSInfo.tipstype"/> 固定值
              <input type="text" id="txtTips" onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)" onblur="this.value=this.value.match(/\d+\.?\d{0,2}/)" :value="currentSInfo.tips" />
              <div style="padding-left:5px;" v-if="currentSInfo.tipstype=='1'"> %</div>
              </div>
              </td>
          </tr>
          
          <!-- <tr>
            <td>零售单位</td>
            <td><input type="text" v-model.trim="currentSInfo.punitls" /></td>
          </tr>
          <tr>
            <td>可零售数量<span>*</span></td>
            <td>
              <input
                type="text"
                v-int-only
                v-model.trim="currentSInfo.pcountls"
                @change="calCost()"
              />
            </td>
          </tr>
          <tr>
            <td>零售成本</td>
            <td>
              <input type="text" readonly v-model.trim="currentSInfo.pcostls" />
            </td>
          </tr> -->
        </table>
        <div class="detail_btnsave">
          <button
            class="btn btn-default"
            @click="cancelUpdate"
          >
            <i class="fa fa-circle-thin" aria-hidden="true"></i>&nbsp;
            取消
          </button>
          &nbsp;
          <button
            class="btn btn-default"
            @click="saveStorage"
            id="btnSave"
            :disabled="submitSaving"
          >
            <i class="fa fa-save" aria-hidden="true"></i>&nbsp;
            {{ submitSaving ? "保存中.." : "更新税费和押金" }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import qs from "qs";

export default {
  name: "UpdateProduct",
  data() {
    return {
      tipstype:'1',
      submitSaving: false,
    };
  },
  props: {
    currentSInfo: {
      type: [],
      required: true,
    },
    taxesList: {
      type: [],
      required: true,
    },
    isshow: {
      type: Boolean,
      required: true,
    }
    
  },
  computed: {
    
  },
  
  methods: {
    changeDeliver(){
      if(this.tipstype=="1"){
        
      }else{
        
      }
      
    },
    setunitls(){
      this.currentSInfo.punitls=this.currentSInfo.punit
    },
    calCost() {
      if (this.currentSInfo.pprice && this.currentSInfo.newcount) {
        this.currentSInfo.pcost =
          (this.currentSInfo.pprice * this.currentSInfo.newcount).toFixed(2);
      }
      this.currentSInfo.pcostls=this.currentSInfo.pprice.toFixed(2)
      this.currentSInfo.pcountls=this.currentSInfo.newcount

      // if (this.currentSInfo.pcountls) {
      //   this.currentSInfo.pcostls = parseFloat(
      //     this.currentSInfo.pprice / this.currentSInfo.pcountls
      //   ).toFixed(2);
      // }
    },
    
    cancelUpdate() {
      this.$emit("clickT2")
    },
    async saveStorage() {
        let taxid=  document.getElementById("sltTax").value
        let tdeposit= document.getElementById("txtDeposit").value.trim()
        let ttips= document.getElementById("txtTips").value.trim()
        if(tdeposit=="") {
            tdeposit=0
        }
        if(ttips=="") {
            ttips=0
        }


    //   if (this.checkInput()) {
        // this.currentSInfo.changecount=this.currentSInfo.newcount-this.currentSInfo.pcount
        // if(this.currentSInfo.pyxq === ""){
        //   this.currentSInfo.pyxq=null
        // }
        this.submitSaving = true;
        const res = await this.$post(
          this.GLOBAL.serverAddress + "/updatePosit/",
          {
            pid: this.currentSInfo.id,
            deposit: tdeposit,
            tips:ttips,
            taxid:taxid,
            tipstype:this.currentSInfo.tipstype

          }
        );
          console.log(res);

        if (res.status === 200) {
          this.$emit("clickT")
            this.submitSaving = false
            alert("更新成功")
        }

    //   }
    },
    //处理回车时间，自动跳转到下一个输入框
    addEnterListener() {
      if (window.__completeEnterBind__) return;
      window.addEventListener("keydown", this.enterCallback);
      window.__completeEnterBind__ = true;
    },
    removeEnterListener() {
      window.removeEventListener("keydown", this.enterCallback);
      window.__completeEnterBind__ = false;
    },
    enterCallback(e) {
      function findFormItem(el) {
        const parent = el.parentElement;
        // console.log(parent);
        return parent;
        // if (!parent) return document.body;
        // if (
        //   parent.className.includes("form-group")
        //   // &&
        //   // parent.className.includes("el-form-item--small")
        // ) {
        //   return parent;
        // }
        // return findFormItem(parent);
      }
      function findInput(container) {
        let nextEl = container.nextElementSibling;
        // console.log(nextEl);
        if (!nextEl) {
          nextEl = container.parentElement.nextElementSibling;
        }
        // return;
        let input = nextEl.querySelector("input");
        // while (input.id === "el-select") {
        //   nextEl = nextEl.nextElementSibling;
        //   if (!nextEl) return;
        //   input = nextEl.querySelector("input");
        // }
        // if (input.className.includes("el-input__inner")) return input;
        return input;
      }
      // console.log(e.keyCode);
      if (e.keyCode === 13) {
        // console.log('5');
        const container = findFormItem(document.activeElement);
        findInput(container) && findInput(container).focus();
      }
    }     

  },
  mounted() {
    this.addEnterListener();
  },
  destroy() {
    this.removeEnterListener();
  },
};
</script>

<style scoped>
input {
  /* border: 0px solid #e0e0e0; */
  padding: 5px 8px;
  /* color: #616161; */
  /* width: 100%; */
  font-size: 0.85em;
  font-weight: 300;
  height: 30px;
  /* -webkit-appearance: none; */
  /* outline: none; */
  font-family: "Muli-Regular";
  /* background: none; */
  /* box-shadow: none !important; */
  text-align: center;
}

.detail_btnsave {
  text-align: center;
  margin-top: 10px;
}
.search_page_list {
  width: 100%;
  position: relative;
}

.screen_cent {
  width: 500px;
  height: 600px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 99;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
}

.screen_data {
  padding: 20px;
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.show_view-enter {
  animation: show_view-dialog-fade-in 0.2s ease;
}

.show_view-leave {
  animation: show_view-dialog-fade-out 0.2s ease forwards;
}

.show_view-enter-active {
  animation: show_view-dialog-fade-in 0.2s ease;
}

.show_view-leave-active {
  animation: show_view-dialog-fade-out 0.2s ease forwards;
}

@keyframes show_view-dialog-fade-in {
  0% {
    transform: translateX(500px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes show_view-dialog-fade-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(500px);
  }
}

table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: right;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
  /* text-align: right;
  padding-right: 5px; */
}
span {
  color: red;
}
</style>